<template>
    <div class="">
        <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_length" id="datatable_length">
                <label>Reports
                    <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                        @change="getReportsBysearchChild">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>500</option>
                    </select>
                    entries</label>
            </div>
            <div class="align-items-center d-flex justify-content-center justify-content-lg-end flex-wrap mt-3 mt-lg-0">
                <div id="datatable_filter" class="dataTables_filter">
                    <label>Search :<input type="search" class="" placeholder="Search" aria-controls="datatable"
                            v-model="listConfig.search_string" v-on:keyup.enter="getReportsBysearchChild" />
                        <a href="javascript:void(0)" @click="getReportsBysearchChild" class="search_icon cntr_search ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                                viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </a>
                        <a href="javascript:void(0)" v-if="listConfig.search_string"
                            @click="listConfig.search_string = null; getReportsBysearchChild()"
                            class="crossicon cntr_cross">
                            X
                        </a>
                    </label>
                </div>
                <div class="user_report_delete">
                    <div v-if="ergoCheckbox.length" @click="deleteBulkDataChild()"
                        class="region_select status_box new_status status_select mb-4 mb-lg-5">
                        <a href="javascript:" class="comman_icons xl_download">
                            <span class="ml_10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 13.503 14.94" style="margin-left: 10px; margin-top: -3px;"><path data-name="Path 108" d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5" transform="translate(-3 -2)" style="fill: none; stroke: rgb(169, 169, 169); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></path></svg>
                                <b>Delete</b>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
        <div class="table-responsive table_audit_log">
            <table class="table table-bordered table dataTable no-footer">
                <thead>
                    <tr>
                        <th style="min-width: 10px" class="sort sml_check">
                            <div class="form_check_box tp_mr d-flex align-items-center mt-1">
                                <input v-if="data_length == 0" type="checkbox" aria-label="..."
                                    class="form-check-input ms-0" disabled />
                                <input v-else type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                                    class="form-check-input ms-0" />
                                <label for="flexCheckDefault" class=""> </label>
                            </div>
                        </th>
                        <th class="sort">
                            <SortIcons :lable="'First Name  '" :listConfig="listConfig" :sortBy="'first_name'"
                                @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                            <SortIcons :lable="'Last Name  '" :listConfig="listConfig" :sortBy="'last_name'"
                                @sorting="sorting($event)" />
                        </th>
                        <th>Email Address</th>
                        <th class="sort DOA">
                            <SortIcons :lable="'Date Of Eval  '" :listConfig="listConfig" :sortBy="'created_at'"
                                @sorting="sorting($event)" />
                        </th>
                        <th scope="col" class="sort name_evaluator move_icon">
                            <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                                @sorting="sorting($event)" />
                            <svg style="margin-left: 10px"
                                v-tooltip="'Re-Review, In Review, Declined, Finalized, Evaluator Reviewed, Evaluator QC Reviewed, Hidden & Pending Client Approval'"
                                xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                                viewBox="0 0 14 18.346">
                                <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                                <path data-name="Path 9427"
                                    d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                                    transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                            </svg>
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, indx) in reports.records" :key="indx">
                        <td style="min-width: 10px">
                            <div class="form_check_box d-flex align-items-center">
                                <input type="checkbox" v-model="ergoCheckbox" :value="report.id" aria-label="..."
                                    :id="'flexCheckDefault' + indx" class="form-check-input ms-0" />
                                <label :for="'flexCheckDefault' + indx" class=""></label>
                            </div>
                        </td>
                        <td>
                            <p class="">{{ report.first_name ? report.first_name :
                                'Not Answered' }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.last_name ? report.last_name :
                                'Not Answered' }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.user_email }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.formated_date ? report.formated_date :
                                report.formated_assessment_date }}</p>
                        </td>
                        <td>
                            <p v-bind:class="switchStatusColorChild(report.status)">
                                {{ report.status }}
                            </p>
                        </td>
                        <td>
                            <div class="edit_btn">
                                <p>
                                    <a href="#" class="view-dshboad-btn router-link-exact-active router-link-active p-2"
                                        title="Data Set" data-bs-toggle="modal"
                                        @click="showReportDataSetChild(report.dataset)" data-bs-target="#dataset">
                                        <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')"
                                            alt="img" />
                                    </a>
                                    <router-link
                                        :to="{ name: 'alchemer_view_user_reports', params: { userid: $route.params.id, id: report.u_id }, }"
                                        target="_blank" title="View Report">
                                        <img class="edit-icon" src="../../assets/images/dashboard/View_Icon.svg"
                                            alt="img" />
                                    </router-link>
                                    <a href="#" aria-current="page" @click="getSingleReportHistoryChild(report.id)"
                                        class="router-link-exact-active router-link-active view-dshboad-btn p-2"
                                        title="History" data-bs-toggle="modal" data-bs-target="#history">
                                        <img :src="require('@/assets/images/dashboard/timer.png')" alt="img"
                                            class="edit-icon">
                                    </a>
                                    <!-- <a href="javascript:void(0)" @click="openModelChild(report.u_id)" data-bs-toggle="modal"
                                        data-bs-target="#submit_form_and_download" title="Delete">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            style="margin-left: 10px; margin-top: -3px" viewBox="0 0 13.503 14.94">
                                            <path data-name="Path 108"
                                                d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                                                transform="translate(-3 -2)" style="
                              fill: none;
                              stroke: #999292;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            "></path>
                                        </svg>
                                    </a> -->
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="data_length == 0">
                        <td colspan="7" class="text-center">No Records found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="float-lg-start text-sm-center">{{ positionText }}</p>
        <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length" :perPage="itemsPerPage"
            :currentPage.sync="currentPage" @pagechanged="onPageChange" />
    </div>
</template>
<script>
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
export default {
    name: "ErgoEval",
    props: ["reports", "listConfig", "data_length", "pages", "itemsPerPage", "currentPage", "onPageChange", "positionText", "btnText", "sorting", "ergoCheckbox"],
    components: {
        VPagination,
        SortIcons
    },
    computed: {
        selectAll: {
            get: function () {
                return this.reports.records
                    ? this.ergoCheckbox.length == this.reports.records.length
                    : false;
            },
            set: function (value) {
                var ergoCheckbox = [];
                if (value) {
                    this.reports.records.forEach(function (schReq) {
                        ergoCheckbox.push(schReq.id);
                    });
                }
                this.ergoCheckbox = ergoCheckbox;
                console.log("ergoCheckbox", this.ergoCheckbox)
            },
        },
    },
    methods: {
        getReportsBysearchChild() {
            this.$parent.ergo_itemsPerPage = this.itemsPerPage;
            this.$parent.getReportsBysearchErgo();
        },
        openModelChild(uid) {
            this.$parent.openModel(uid);
        },
        showReportDataSetChild(dataset) {
            this.$parent.showReportDataSet(dataset, "Ergo Eval");
        },
        getSingleReportHistoryChild(reportId) {
            var apiUrl = process.env.VUE_APP_API_URL + "/api/reports-history";
            this.$parent.getSingleReportHistory(reportId, apiUrl);
        },
        switchStatusColorChild(status) {
            let statusClass;
            switch (status) {
                case 'Re-Review':
                    statusClass = "blue_review";
                    break;
                case 'In Review':
                    statusClass = "gray_review";
                    break;
                case 'Hidden':
                    statusClass = "gray_review";
                    break;
                case 'Evaluator Reviewed':
                    statusClass = "orange_review";
                    break;
                case 'Evaluator QC Reviewed':
                    statusClass = "orange_review";
                    break;
                case 'Finalized':
                    statusClass = "light_blue_review";
                    break;
                case 'Declined':
                    statusClass = "pink_review";
                    break;
                case 'Pending Client Approval':
                    statusClass = "gold_review";
                    break;
                default:
                    statusClass = "";
            }
            return statusClass;
        },
        deleteBulkDataChild() {
            this.$parent.ergoCheckbox = this.ergoCheckbox;
            var apiUrl = process.env.VUE_APP_API_URL + "/api/delete-bulk-reports";
            this.$parent.deleteBulkData('ergoReports', apiUrl);
        }
    }
};
</script>