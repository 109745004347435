<template>
    <div class="">
        <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_length" id="datatable_length">
                <label>Reports
                    <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                        @change="getReportsBysearchChild">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>500</option>
                    </select>
                    entries</label>
            </div>
            <div class="align-items-center d-flex justify-content-center justify-content-lg-end flex-wrap mt-3 mt-lg-0">
                <div id="datatable_filter" class="dataTables_filter">
                    <label>Search :<input type="search" class="" placeholder="Search" aria-controls="datatable"
                            v-model="listConfig.search_string" v-on:keyup.enter="getReportsBysearchChild" />
                        <a href="javascript:void(0)" @click="getReportsBysearchChild" class="search_icon cntr_search ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                                viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </a>
                        <a href="javascript:void(0)" v-if="listConfig.search_string"
                            @click="listConfig.search_string = null; getReportsBysearchChild()"
                            class="crossicon cntr_cross">
                            X
                        </a>
                    </label>
                </div>
                <div class="user_report_delete">
                    <div v-if="scheduledCheckbox.length" @click="deleteBulkDataChild()"
                        class="region_select status_box new_status status_select mb-4 mb-lg-5">
                        <a href="javascript:" class="comman_icons xl_download">
                            <span class="ml_10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 13.503 14.94" style="margin-left: 10px; margin-top: -3px;"><path data-name="Path 108" d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5" transform="translate(-3 -2)" style="fill: none; stroke: rgb(169, 169, 169); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></path></svg>
                                <b>Delete</b>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive table_audit_log">
            <table class="table table-bordered table dataTable no-footer">
                <thead>
                    <tr>
                        <th style="min-width: 10px" class="sort sml_check">
                            <div class="form_check_box tp_mr d-flex align-items-center mt-1">
                                <input v-if="data_length == 0" type="checkbox" aria-label="..."
                                    class="form-check-input ms-0" disabled />
                                <input v-else type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                                    class="form-check-input ms-0" />
                                <label for="flexCheckDefault" class=""> </label>
                            </div>
                        </th>
                        <th class="sort">
                            <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'first_name'"
                                @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                            <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'last_name'"
                                @sorting="sorting($event)" />
                        </th>
                        <th>Email Address</th>
                        <th class="sort DOA">
                            <SortIcons :lable="'Date Of Request'" :listConfig="listConfig" :sortBy="'created_at'"
                                @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                            <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                                @sorting="sorting($event)" />
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, indx) in reports.records" :key="indx">
                        <td style="min-width: 10px">
                            <div class="form_check_box d-flex align-items-center">
                                <input type="checkbox" v-model="scheduledCheckbox" :value="report.id" aria-label="..."
                                    :id="'flexCheckDefault' + indx" class="form-check-input ms-0" />
                                <label :for="'flexCheckDefault' + indx" class=""></label>
                            </div>
                        </td>
                        <td>
                            <p class="">{{ report.first_name ? report.first_name :
                                'Not Answered' }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.last_name ? report.last_name :
                                'Not Answered' }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.user_email }}</p>
                        </td>
                        <td>
                            <p class="">{{ report.formated_date ? report.formated_date :
                                report.formated_assessment_date }}</p>
                        </td>
                        <td>
                            <p> {{ report.status }} </p>
                        </td>
                        <td>
                            <div class="edit_btn">
                                <p>
                                    <a href="#" @click="showReportDataSetChild(report.dataset)"
                                        class="view-dshboad-btn router-link-exact-active router-link-active p-2"
                                        title="Data Set" data-bs-toggle="modal" data-bs-target="#dataset">
                                        <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')"
                                            alt="img" />
                                    </a>
                                    <a href="#" aria-current="page" @click="getScheduleRequestHistoryChild(report.id)"
                                        class="router-link-exact-active router-link-active view-dshboad-btn p-2"
                                        title="History" data-bs-toggle="modal" data-bs-target="#history">
                                        <img :src="require('@/assets/images/dashboard/timer.png')" alt="img"
                                            class="edit-icon">
                                    </a>
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="data_length == 0">
                        <td colspan="7" class="text-center">No Records found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="float-lg-start text-sm-center">{{ positionText }}</p>
        <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length" :perPage="itemsPerPage"
            :currentPage.sync="currentPage" @pagechanged="onPageChange" />
    </div>
</template>
<script>
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
export default {
    name: "Schedulerequest",
    props: ["reports", "listConfig", "data_length", "pages", "itemsPerPage", "currentPage", "onPageChange", "positionText", "btnText", "sorting", "scheduledCheckbox"],
    components: {
        VPagination,
        SortIcons
    },
    computed: {
        selectAll: {
            get: function () {
                return this.reports.records
                    ? this.scheduledCheckbox.length == this.reports.records.length
                    : false;
            },
            set: function (value) {
                var scheduledCheckbox = [];
                if (value) {
                    this.reports.records.forEach(function (schReq) {
                        scheduledCheckbox.push(schReq.id);
                    });
                }
                this.scheduledCheckbox = scheduledCheckbox;
                console.log("scheduledCheckbox", this.scheduledCheckbox)
            },
        },
    },
    methods: {
        getReportsBysearchChild() {
            this.$parent.schedule_itemsPerPage = this.itemsPerPage;
            this.$parent.getReportsBysearchSchedule();
        },
        openModelChild(uid) {
            this.$parent.openModel(uid);
        },
        showReportDataSetChild(dataset) {
            this.$parent.showReportDataSet(dataset, "Schedule Request");
        },
        getScheduleRequestHistoryChild(scheduleId) {
            var apiUrl = process.env.VUE_APP_API_URL + "/api/request-history";
            this.$parent.getSingleReportHistory(scheduleId, apiUrl);
        },
        deleteBulkDataChild() {
            this.$parent.scheduledCheckbox = this.scheduledCheckbox;
            var apiUrl = process.env.VUE_APP_API_URL + "/api/delete-schedule-requests";
            this.$parent.deleteBulkData('scheduledRequest', apiUrl);
        }
    }
};
</script>