<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-lg-7">
            <div class="heading">
              <h2 v-if="first_name">{{ first_name | capitalize }}'s My Reports</h2>
              <h2 v-else>{{ user_email }}'s My Reports</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-12 col-lg-5" v-if="checkReportType == 3">
            <div class="alche_btn tab_report toggle_switch list_card_switch me-0 my-2 text-center text-sm-end">
              <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs btn-reports">
                <li role="presentation" class="nav-item list-item">
                  <button id="percent-tab" @click="getReports('alchemer')" data-bs-toggle="tab" data-bs-target="#percent"
                    type="button" role="tab" aria-controls="percent" aria-selected="true"
                    class="nav-link list-link active">
                    Current Reports
                  </button>
                </li>
                <li role="presentation" class="nav-item list-item">
                  <button id="hash-tab" @click="getReports('qualtrics')" data-bs-toggle="tab" data-bs-target="#hash"
                    type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link list-link">
                    Previous Reports
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-md-12">
            <div id="myTabContent" class="tab-content ">
              <div id="percent" role="tabpanel" aria-labelledby="#percent"
                :class="checkReportType != 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
                <!-- Alchemer Reports Self-Assessment-->
                <div class="row" v-if="!hideAssessment">
                  <div class="col-md-12">
                    <div class="table_card summry_report">
                      <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-12 col-xxl-12">
                          <div class="align-left ">
                            <h3 class="card_heading bold_head">Self-Assessment</h3>
                          </div>
                        </div>
                      </div>
                      <SelfAssessment v-bind:reports="this.reports" v-bind:positionText="this.positionText"
                        v-bind:data_length="this.data_length" v-bind:pages="this.pages"
                        v-bind:itemsPerPage="this.itemsPerPage" v-bind:currentPage="this.currentPage"
                        v-bind:listConfig="this.listConfig" v-bind:onPageChange="this.onPageChange"
                        v-bind:btnText="this.btnText" v-bind:sorting="this.sorting" v-bind:selfCheckbox="selfCheckbox" />
                    </div>
                  </div>
                </div>
                <!-- Alchemer Reports Ergo Eval-->
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_card summry_report">
                      <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-12 col-xxl-12">
                          <div class="align-left ">
                            <h3 class="card_heading bold_head">Ergo Eval</h3>
                          </div>
                        </div>
                      </div>
                      <ErgoEval v-bind:reports="this.ergo_reports" v-bind:positionText="this.positionTextErgo"
                        v-bind:data_length="this.ergo_data_length" v-bind:pages="this.pages_ergo"
                        v-bind:itemsPerPage="this.ergo_itemsPerPage" v-bind:currentPage="this.ergo_currentPage"
                        v-bind:listConfig="this.ergo_listConfig" v-bind:onPageChange="this.onPageChangeErgo"
                        v-bind:btnText="this.btnText" v-bind:sorting="this.ergo_sorting" v-bind:ergoCheckbox="ergoCheckbox" />
                    </div>
                  </div>
                </div>
                <!-- Alchemer Reports Followups-->
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_card summry_report">
                      <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-12 col-xxl-12">
                          <div class="align-left ">
                            <h3 class="card_heading bold_head">Follow-Ups</h3>
                          </div>
                        </div>
                      </div>
                      <Followups v-bind:reports="this.followup_reports" v-bind:positionText="this.positionTextFollowup"
                        v-bind:data_length="this.followup_data_length" v-bind:pages="this.pages_followup"
                        v-bind:itemsPerPage="this.followup_itemsPerPage" v-bind:currentPage="this.followup_currentPage"
                        v-bind:listConfig="this.followup_listConfig" v-bind:onPageChange="this.onPageChangeFollowup"
                        v-bind:btnText="this.btnText" v-bind:sorting="this.followup_sorting" v-bind:followupCheckbox="followupCheckbox" />
                    </div>
                  </div>
                </div>
                 <!-- Alchemer Reports Schedule Request-->
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_card summry_report">
                      <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-12 col-xxl-12">
                          <div class="align-left ">
                            <h3 class="card_heading bold_head">Schedule Request</h3>
                          </div>
                        </div>
                      </div>
                      <Schedulerequest v-bind:reports="this.schedule_reports"
                        v-bind:positionText="this.positionTextSchedule" v-bind:data_length="this.schedule_data_length"
                        v-bind:pages="this.pages_schedule" v-bind:itemsPerPage="this.schedule_itemsPerPage"
                        v-bind:currentPage="this.schedule_currentPage" v-bind:listConfig="this.schedule_listConfig"
                        v-bind:onPageChange="this.onPageChangeSchedule" v-bind:btnText="this.btnText"
                        v-bind:sorting="this.schedule_sorting" v-bind:scheduledCheckbox="scheduledCheckbox"/>
                    </div>
                  </div>
                </div>
              </div>
              <div id="hash" role="tabpanel" aria-labelledby="#hash"
                :class="checkReportType == 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
                <!-- Qualtrics Reports -->
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_card">
                      <div class="row justify-content-end align-items-center pb_40">
                        <div class="col-md-12 col-xl-12 col-xxl-12">
                          <div class="align-left ">
                            <h3 class="card_heading bold_head" v-if="checkReportType == 2">My Reports</h3>
                            <h3 class="card_heading bold_head" v-else>Previous Reports</h3>
                          </div>
                        </div>
                      </div>
                      <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length">
                          <label>Reports
                            <select name="datatable_length" aria-controls="datatable" class=""
                              v-model.number="itemsPerPage_qual" @change="getProductsBysearchQual">
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                              <option>100</option>
                              <option>500</option>
                            </select>
                            entries</label>
                        </div>
                        <div id="datatable_filter" class="dataTables_filter">
                          <label>Search :<input type="search" class="" placeholder="Type Of Assessment"
                              aria-controls="datatable" v-model="listConfig_qual.search_string"
                              v-on:keyup.enter="getProductsBysearchQual" />
                            <a href="javascript:void(0)" @click="getProductsBysearchQual" class="search_icon cntr_search">
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00"
                                class="bi bi-search" viewBox="0 0 16 16">
                                <path
                                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </a>
                            <a href="javascript:void(0)" v-if="listConfig_qual.search_string"
                              @click=" listConfig_qual.search_string = null; getProductsBysearchQual();"
                              class="crossicon cntr_cross">
                              X
                            </a>
                          </label>
                        </div>
                      </div>
                      <div class="table-responsive table_audit_log">
                        <table class="table table-bordered table dataTable no-footer">
                          <thead>
                            <tr>
                              <th class="sort DOA">
                                <SortIcons :lable="'Date Of Assessment  '" :listConfig="listConfig_qual"
                                  :sortBy="'assessment_date'" @sorting="sorting_qual($event)" />
                              </th>
                              <!-- <th class="report_name">Name</th> -->
                              <!-- <th>Email Address</th> -->
                              <th class="sort DOA">
                                <SortIcons :lable="'Type Of Assessment'" :listConfig="listConfig_qual"
                                  :sortBy="'type_of_assessment'" @sorting="sorting_qual($event)" />
                              </th>
                              <th class="report_action">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(report, indx) in reports_qual.records" :key="indx">
                              <td>
                                <p> {{ report.formated_date ? report.formated_date : report.formated_assessment_date }}
                                </p>
                              </td>
                              <!-- <td> <p>{{ first_name }}</p> </td> -->
                              <!-- <td> <p class="email_tag"> <a :href="'mailto:' + report.user_email">{{ report.user_email }}</a> </p> </td> -->
                              <td>
                                <p> {{ report.type_of_assessment ? report.type_of_assessment : "Not Available" }} </p>
                              </td>
                              <td>
                                <div class="edit_btn">
                                  <p>
                                    <!-- <router-link :to="{ name: 'view_my_reports', params: { url: report.u_id }, }" target="_blank" title="View Report"> -->

                                    <router-link
                                      :to="{ name: 'view_user_reports', params: { userid: $route.params.id, id: report.u_id }, }"
                                      target="_blank" title="View Report">
                                      <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                        alt="img" />
                                    </router-link>
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="data_length_qual == 0">
                              <td colspan="3" class="text-center">No Records found</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="float-lg-start text-sm-center">{{ positionTextQual }}</p>
                      <VPagination v-if="data_length_qual" class="dataTables_paginate" :totalPages="pages_qual.length"
                        :perPage="itemsPerPage_qual" :currentPage.sync="currentPage_qual"
                        @pagechanged="onPageChangeQual" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
    <div class="modal user_box_up fade meet_our_team_modal" id="submit_form_and_download" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 pb_0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt_0">
            <div class="mb-3 form-group text-center">
              Why are you deleting this report?
            </div>
            <div class="mb-3 form-group maxheight">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">
                  Reason <span>*</span></label>
                <textarea height="100" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Reason of delete" name="delete_reason" v-model="delete_reason"
                  v-validate="'required|email'" v-on:keyup="countdown" maxlength="150" />
                <label class="text-end w-100">{{ remainingCount }} characters remaining</label>
                <small class="error text-danger">{{ delete_res }}
                </small>
              </div>

            </div>

            <div class="text-center mt_30">
              <button class="btn btn_primary mr_10" type="button" @click="deleteReport()">
                Yes
              </button>
              <button class="btn btn_outline" type="button" @click="closeModel()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dataSet="dataSet"
      v-bind:isEmptyDataSet="isEmptyDataSet" v-bind:dashboard="dashboard" ref="datasetModal"></DatasetModal>
    <HistoryModal v-bind:history="allCommentArr" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction from '../../mixin/commonFunction';
import SelfAssessment from "@/components/reports/SelfAssessment.vue";
import ErgoEval from "@/components/reports/ErgoEval.vue";
import Followups from "@/components/reports/Followups.vue";
import Schedulerequest from "@/components/reports/ScheduleRequest.vue";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import ConfirmDialogue from "@/components/ConfirmDialogue";
import $ from 'jquery';

export default {
  name: "alchemer_reports",
  components: {
    VPagination,
    SortIcons,
    SelfAssessment,
    ErgoEval,
    Followups,
    Schedulerequest,
    DatasetModal,
    HistoryModal,
    ConfirmDialogue
  },
  mixins: [commonFunction],
  data() {
    return {
      first_name: "",
      user_email: "",
      reports: [], /** Self-Assessment Parameters*/
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      data_length: 0,
      selfCheckbox: [],
      selfCheckboxArr: {
        checkboxes: [],
      },

      ergo_reports: [],  /** Ergo Eval Parameters*/
      ergo_currentPage: 1,
      ergo_itemsPerPage: 10,
      ergo_listConfig: {
        offset: (this.ergo_currentPage - 1) * this.ergo_itemsPerPage,
        limit: this.ergo_itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      ergo_data_length: 0,
      ergoCheckbox: [],
      ergoCheckboxArr: {
        checkboxes: [],
      },

      followup_reports: [],  /** Followup Parameters*/
      followup_currentPage: 1,
      followup_itemsPerPage: 10,
      followup_listConfig: {
        offset: (this.followup_currentPage - 1) * this.followup_itemsPerPage,
        limit: this.followup_itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      followup_data_length: 0,
      followupCheckbox: [],
      followupCheckboxArr: {
        checkboxes: [],
      },

      schedule_reports: [],  /** Schedule Parameters*/
      schedule_currentPage: 1,
      schedule_itemsPerPage: 10,
      schedule_listConfig: {
        offset: (this.schedule_currentPage - 1) * this.schedule_itemsPerPage,
        limit: this.schedule_itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      schedule_data_length: 0,
      scheduledCheckbox: [],
      scheduledCheckboxArr: {
        checkboxes: [],
      },

      reports_qual: [],
      currentPage_qual: 1,
      itemsPerPage_qual: 10,
      listConfig_qual: {
        offset: (this.currentPage_qual - 1) * this.itemsPerPage_qual,
        limit: this.itemsPerPage_qual,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      data_length_qual: 0,
      checkReportType: '3',
      delete_id: 0,
      delete_res: "",
      delete_reason: "",
      remainingCount: 150,
      maxCount: 150,
      dataSet: [], /** For dataset */
      dashboard: 'Admin',
      downloadMessage: 'Dataset',
      isEmptyDataSet: true,
      commentArr: [], /** For using common api on history popup */
      addCommentArr: {
        report_id: null,
        comments: null,
      },
      allCommentArr: [],
      checkMount:false,
      saComplete:false,
      eeComplete:false,
      folComplete:false,
      schComplete:false,
      hideAssessment:false,
    };
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    positionTextErgo: function () {
      var ergo_endIndex = this.ergo_currentPage * this.ergo_itemsPerPage,
        ergo_startIndex = (this.ergo_currentPage - 1) * this.ergo_itemsPerPage + 1;
      if (this.ergo_data_length == 0) {
        ergo_startIndex = 0;
      }
      return (
        "Showing " +
        ergo_startIndex +
        " to " +
        (ergo_endIndex > this.ergo_data_length ? this.ergo_data_length : ergo_endIndex) +
        " of " +
        this.ergo_data_length
      );
    },
    positionTextFollowup: function () {
      var followup_endIndex = this.followup_currentPage * this.followup_itemsPerPage,
        followup_startIndex = (this.followup_currentPage - 1) * this.followup_itemsPerPage + 1;
      if (this.followup_data_length == 0) {
        followup_startIndex = 0;
      }
      return (
        "Showing " +
        followup_startIndex +
        " to " +
        (followup_endIndex > this.followup_data_length ? this.followup_data_length : followup_endIndex) +
        " of " +
        this.followup_data_length
      );
    },
    positionTextSchedule: function () {
      var schedule_endIndex = this.schedule_currentPage * this.schedule_itemsPerPage,
        schedule_startIndex = (this.schedule_currentPage - 1) * this.schedule_itemsPerPage + 1;
      if (this.schedule_data_length == 0) {
        schedule_startIndex = 0;
      }
      return (
        "Showing " +
        schedule_startIndex +
        " to " +
        (schedule_endIndex > this.schedule_data_length ? this.schedule_data_length : schedule_endIndex) +
        " of " +
        this.schedule_data_length
      );
    },
    positionTextQual: function () {
      var endIndex_qual = this.currentPage_qual * this.itemsPerPage_qual,
        startIndex_qual = (this.currentPage_qual - 1) * this.itemsPerPage_qual + 1;
      if (this.data_length_qual == 0) {
        startIndex_qual = 0;
      }
      return (
        "Showing " +
        startIndex_qual +
        " to " +
        (endIndex_qual > this.data_length_qual ? this.data_length_qual : endIndex_qual) +
        " of " +
        this.data_length_qual
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    pages_ergo() {
      if (this.ergo_data_length <= this.ergo_itemsPerPage) {
        return [1];
      }
      let ergo_pages_array = [
        ...Array(Math.ceil(this.ergo_data_length / this.ergo_itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return ergo_pages_array;
    },
    pages_followup() {
      if (this.followup_data_length <= this.followup_itemsPerPage) {
        return [1];
      }
      let followup_pages_array = [
        ...Array(Math.ceil(this.followup_data_length / this.followup_itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return followup_pages_array;
    },
    pages_schedule() {
      if (this.schedule_data_length <= this.schedule_itemsPerPage) {
        return [1];
      }
      let schedule_pages_array = [
        ...Array(Math.ceil(this.schedule_data_length / this.schedule_itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return schedule_pages_array;
    },
    pages_qual() {
      if (this.data_length_qual <= this.itemsPerPage_qual) {
        return [1];
      }
      let pages_array_qual = [
        ...Array(Math.ceil(this.data_length_qual / this.itemsPerPage_qual)).keys(),
      ].map((e) => e + 1);
      return pages_array_qual;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getSingleUserReport();
    },
    ergo_currentPage(ergo_newValue) {
      this.ergo_listConfig.offset = (ergo_newValue - 1) * this.ergo_itemsPerPage;
      this.getSingleUserErgoReport();
    },
    followup_currentPage(followup_newValue) {
      this.followup_listConfig.offset = (followup_newValue - 1) * this.followup_itemsPerPage;
      this.getSingleUserFollowupReport();
    },
    schedule_currentPage(schedule_newValue) {
      this.schedule_listConfig.offset = (schedule_newValue - 1) * this.schedule_itemsPerPage;
      this.getSingleUserScheduleReport();
    },

    currentPage_qual(newValue_qual) {
      this.listConfig_qual.offset = (newValue_qual - 1) * this.itemsPerPage_qual;
      this.getQualData();
    },
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
    };
    this.ergo_listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
    },

      this.followup_listConfig = {
        offset: (this.followup_currentPage - 1) * this.followup_itemsPerPage,
        limit: this.followup_itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },

      this.schedule_listConfig = {
        offset: (this.schedule_currentPage - 1) * this.schedule_itemsPerPage,
        limit: this.schedule_itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },

      this.listConfig_qual = {
        offset: (this.currentPage_qual - 1) * this.itemsPerPage_qual,
        limit: this.itemsPerPage_qual,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      };

    var clientData = this.$store.getters["auth/authUser"];
    if (clientData.client.report_type == 1) {
      this.checkReportType = '1';
      this.getReports('alchemer');
    } else if (clientData.client.report_type == 2) {
      this.checkReportType = '2';
      this.getReports('qualtrics');
    } else {
      this.checkReportType = '3';
      this.getReports('alchemer');
    }
    this.checkMount = true;
  },
  methods: {
    setTimeoutLoader() {
      setTimeout(() => {
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
      }, 1000);
    },
    openModel(delete_report) {
      this.delete_reason = '';
      this.delete_res = '';
      this.delete_id = delete_report;
    },
    closeModel() {
      document.getElementsByClassName("btn-close")[0].click();
    },
    /** Function is not in used after QA it should be removed */
    deleteReport() {
      var _this = this;
      let report_id = _this.delete_id;
      var delete_reason = _this.delete_reason;

      if (!delete_reason) {
        _this.delete_res = "Reason is required.";
        return false;
      } else {
        _this.delete_res = '';
      }
      var requestData = { 'reason': delete_reason, 'id': report_id };
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/delete-report/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.encodeAPIRequest(requestData),
      };
      _this
        .axios(config)
        .then(({ data }) => {
          _this.closeModel();
          _this.$toast.success(data.message, {
            position: "top-right",
            duration: 5000,
          });
          setTimeout(() => {
            _this.getReports('alchemer');
          }, 6000);
        })
        .catch(({ response }) => {
          _this.closeModel();
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          setTimeout(() => {
            _this.getReports('alchemer');
          }, 6000);

        });
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.delete_reason.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log('Haserror:', this.hasError);
        return;
      }
    },
    getReports(repoType) {
      if (repoType == 'alchemer') {
        this.listConfig.search_string = null;
        this.getReportsBysearch();
        this.getReportsBysearchErgo();
        this.getReportsBysearchFollowup();
        this.getReportsBysearchSchedule();
      }
      else if (repoType == 'qualtrics') {
        this.listConfig_qual.search_string = null;
        this.getProductsBysearchQual();
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    onPageChangeErgo(page) {
      this.ergo_currentPage = page;
    },
    onPageChangeFollowup(page) {
      this.followup_currentPage = page;
    },
    onPageChangeSchedule(page) {
      this.schedule_currentPage = page;
    },
    onPageChangeQual(pages) {
      this.currentPage_qual = pages;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getSingleUserReport();
    },
    ergo_sorting($event) {
      this.ergo_listConfig.sortBy == $event.sortBy;
      this.ergo_listConfig.order == $event.order;
      this.getSingleUserErgoReport();
    },
    followup_sorting($event) {
      this.followup_listConfig.sortBy == $event.sortBy;
      this.followup_listConfig.order == $event.order;
      this.getSingleUserFollowupReport();
    },
    schedule_sorting($event) {
      this.schedule_listConfig.sortBy == $event.sortBy;
      this.schedule_listConfig.order == $event.order;
      this.getSingleUserScheduleReport();
    },

    sorting_qual($event) {
      this.listConfig_qual.sortBy == $event.sortBy;
      this.listConfig_qual.order == $event.order;
      this.getQualData();
    },

    getReportsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getSingleUserReport();
    },
    getReportsBysearchErgo() {
      this.ergo_currentPage = 1;
      this.ergo_listConfig.limit = this.ergo_itemsPerPage;
      this.getSingleUserErgoReport();
    },
    getReportsBysearchFollowup() {
      this.followup_currentPage = 1;
      this.followup_listConfig.limit = this.followup_itemsPerPage;
      this.getSingleUserFollowupReport();
    },
    getReportsBysearchSchedule() {
      this.followup_currentPage = 1;
      this.schedule_listConfig.limit = this.schedule_itemsPerPage;
      this.getSingleUserScheduleReport();
    },
    getProductsBysearchQual() {
      this.currentPage_qual = 1;
      this.listConfig_qual.limit = this.itemsPerPage_qual;
      this.getQualData();
    },
    /** Self Asssessment Reports*/
    getSingleUserReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/alchemer-employee-reports/" + this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {

          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            if(decodedJson.hideAssessment){
              _this.hideAssessment = true;
            }
            _this.first_name = decodedJson.first_name;
            _this.user_email = decodedJson.user_email;
            _this.reports = decodedJson;
            _this.data_length = decodedJson.counts;
            _this.saComplete = true;
            if(_this.checkMount){
              if(_this.eeComplete && _this.folComplete && _this.schComplete)
              {
                _this.setTimeoutLoader();
              }
            }else{
              _this.setTimeoutLoader();
            }
            
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }

        });
    },
    /** Ergo Eval Reports*/
    getSingleUserErgoReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/alchemer-employee-ergo-reports/" + this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.ergo_listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {

          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.ergo_reports = decodedJson;
            _this.ergo_data_length = decodedJson.counts;
            _this.eeComplete = true;
            if(_this.checkMount){
              if(_this.saComplete && _this.folComplete && _this.schComplete)
              {
                _this.setTimeoutLoader();
              }
            }else{
              _this.setTimeoutLoader();
            }
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }

        });
    },
    /** Followup Reports*/
    getSingleUserFollowupReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/alchemer-employee-followup-reports/" + this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.followup_listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.followup_reports = decodedJson;
            _this.followup_data_length = decodedJson.counts;
            _this.folComplete = true;
            if(_this.checkMount){
              if(_this.saComplete && _this.eeComplete && _this.schComplete)
              {
                _this.setTimeoutLoader();
              }
            }else{
              _this.setTimeoutLoader();
            }
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }

        });
    },
    /** Schedule Request*/
    getSingleUserScheduleReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/alchemer-employee-schedule-reports/" + this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.schedule_listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.schedule_reports = decodedJson;
            _this.schedule_data_length = decodedJson.counts;
            _this.schComplete = true;
            if(_this.checkMount){
              if(_this.saComplete && _this.eeComplete && _this.folComplete)
              {
                _this.setTimeoutLoader();
              }
            }else{
              _this.setTimeoutLoader();
            }
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({ name: "all_user" });
          }

        });
    },
    getQualData() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/employee-reports/" + this.$route.params.id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.listConfig_qual) },
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.first_name = decodedJson.first_name;
            _this.reports_qual = decodedJson;
            _this.data_length_qual = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          } else {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          if (response.data.type == false) {
            _this.$router.push({ name: "login" });
          }
        });
    },
    showReportDataSet(dataSet, type) {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if (dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if (dataSet[item].options) {
              for (const item1 in dataSet[item].options) {
                if (dataSet[item].options[item1].answer) {
                  if (dataSet[item].answer)
                    dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if (dataSet[item].subquestions) {
              for (const item2 in dataSet[item].subquestions) {
                if (dataSet[item].subquestions[item2].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          _this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          _this.isEmptyDataSet = Object.keys(dataSet).length === 0;
          _this.downloadMessage = "Dataset of " + type + " report";
        } else {
          _this.dataSet = [];
          _this.isEmptyDataSet = true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    getSingleReportHistory(reportid, apiUrl) {
      if (reportid) {
        this.showHistory = false;
        this.commentArr = [];
        this.allCommentArr = [];
        this.addCommentArr.report_id = reportid;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: apiUrl,
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(_this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.allCommentArr = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              this.allCommentArr = [];
              if (response.status == 401) {
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
              }
            });
        } else {
          $('.modal-backdrop').remove()
          $(document.body).removeClass("modal-open");
          this.tokenExpired();
        }
      }
    },
    async deleteBulkData(type,apiUrl) {
      let reportType;
      let reportArray;
      let reortMessage;
      switch (type) {
        case 'scheduledRequest':
        reportType = this.scheduledCheckbox;
        reportArray = this.scheduledCheckboxArr;
        reortMessage = "schedule requests";
        break;
        case 'followupReport':
        reportType = this.followupCheckbox;
        reportArray = this.followupCheckboxArr;
        reortMessage = "follow-ups";
        break;
        case 'ergoReports':
        reportType = this.ergoCheckbox;
        reportArray = this.ergoCheckboxArr;
        reortMessage = "ergo eval report(s)";
        break;
        case 'selfReports':
        reportType = this.selfCheckbox;
        reportArray = this.selfCheckboxArr;
        reortMessage = "Self-Assessment report(s)";
        break;
        default:
        reportType = [];
        reportArray = [];
        reortMessage = '';
      }

      if (reportType.length) {
        const confirmDelete = await this.$refs.confirmDialogue.show({
          title: "Delete Request",
          message: "Are you sure you want to delete "+reortMessage+" ?",
          okButton: "Delete",
        });
        if (confirmDelete) {
          reportArray.checkboxes = reportType;
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            let config = {
              method: "post",
              url: apiUrl,
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: {request_data : this.encodeAPIRequest(reportArray)},
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  reportType = [];
                  this.scheduledCheckbox = []; // reset schedule request
                  this.followupCheckbox = []; // reset Followup
                  this.ergoCheckbox = []; // reset Ergo eval
                  this.selfCheckbox = []; // reset Ergo eval

                  this.getReports('alchemer');
                  // setTimeout(() => {
                  //   _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  // }, 500);
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                }
                
              })
              .catch(() => {
                //
              });
          } else {
            this.tokenExpired();
          }
        }
      } else {
        setTimeout(() => {
          reportType.length = 0;
          this.getReports('alchemer');
          alert()
        }, 100);
      }
    },
  },
};

</script>